<template>
  <div class="iframe-container">
    <iframe v-if="dashboardUrl" width="100%" frameborder="0" :src=dashboardUrl></iframe>
  </div>
</template>

<script>
import ApiProperties from '../../../src/main/resources/application'
import get from "just-safe-get";


export default {
  name: 'reports',
  data: function() {
    return {
      reports: [],
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: false
        },
        {
          key: 'actions',
          label: '',
          sortable: false
        },
      ],
      errors: [],
      dashboardUrl: null,
    }
  },

  created() {
    console.log("Get dashboard URL");
    this.$http.get(ApiProperties.api.reportConfig).then(response => {
      console.log("set dashboard URL from SAM: " + response.data.dashboardUrl);
      this.dashboardUrl = response.data.dashboardUrl;
    });
  },
}
</script>
<style lang="scss">
  .iframe-container {
    overflow: auto;
    /* 16:9 aspect ratio */
    padding-top: 56.25%;
    position: relative;
    background-image: url('../../public/img/spinner.gif');
    background-position: center;
    background-repeat: no-repeat;

    iframe {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      background: transparent;
    }
  }
</style>

